.form-control {
    height: 55px;
    width: 100%;
    float: left;
    border-radius: 0px;
    color: #111111;
    padding: 15px;
    display: inline-block;
    font-size: 16px;
    transition: all linear 0.5s;
    margin-bottom: 10px;
    border: solid 1px #e5e5e5;
    border: 1px solid #cacaca;
}
.full-button
{
    padding: 11px 30px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    background-color: #000;
    color: #fff;
    padding-left: 30px !important;
    padding-right: 30px !important;
    border: 1px solid #fff;
    margin-bottom: 20px !important;
}
.clear-button
{
    float: right;
    padding: 11px 30px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    background-color: #0f0;
    color: #000 !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    border: 1px solid #0f0;
    margin-bottom: 20px !important;
}
.search-section
{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.expert-expertise-list
{
    float: left;
    width: 90%;
}
.remove-expertise
{
    color: red;
    cursor: pointer;
    float: right;
}
