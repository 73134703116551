.home-slider img
{
    object-fit: cover;
    object-position: 50% 50%;
    max-width: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1321px;
    height: 513.05px;
    overflow: hidden;
    pointer-events: none;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, opacity;
    margin-top: -49.525px;
    transform: translate3d(0px, 203.025px, 0px);
}
.margin-left-10
{
    margin-left: 20px !important;
}
.black-background
{
    background-color: rgb(17, 17, 17) !important;
}
.margin-top-20
{
    margin-top: -25px !important;
    margin-left: 52% !important;
}
.de-images img {
    border-radius: 50% !important;
    border: 6px solid #fff !important;
    margin-top: -600px !important;
}
.di-text
{
    padding: 15px !important;
    border-radius: 5px !important;
    border:  3px solid #fff !important;
    margin-top: 190px !important;
}
.image-bar
{
    width: 200px;
    height: 600px;
    margin-left: 25%;
    background-color: #000;
    z-index: -99;
}
.read-more
{
    float: left !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    border: 1px solid #000;
    margin-bottom: 20px !important;
}
.post-content .date-box {
  width: auto !important;
}
.post-content .date-box .m {
  font-size: 16px !important;
  font-weight: normal !important;
}
.full-width
{
    width: 100% !important;
}
.background
{
    background-color: #eee !important;
}
.card-nav
{
    text-align: center !important;
    padding: 20px !important;
    height: 370px;
}
.card-nav i {
    background: #000 !important;
    height: 80px;
    border-radius: 50% !important;
    color: #fff !important;
    width: 80px;
    line-height: 80px;
}
.card-nav h2 {
    margin-top: 20px !important;
}
.card-nav p {
    text-align: justify !important;
}
.search-field {
    border: solid 1px rgba(255, 255, 255, .9);
    padding-left: 20px;
    background-color: transparent;
    height: 55px;
    width: 100%;
    color: #fff;
}
.data-loader
{
    margin-top: 10%;
    margin-left: 47.5%;
}
