.database-section
{
	/*background-color: #f5f5f5;*/
}
.black-background
{
  background-color: #000 !important;
  border: 3px solid #1c1c1d !important;
}
.card
{
	background-color: #fff !important;	
	border-radius: 3px !important;
	border: 1px solid transparent !important;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12),0 3px 1px -2px rgba(0,0,0,.2) !important;
}
.white-background
{
	background-color: #fff !important;
}
.read-more
{
	background: #19191A !important;
	color: #000 !important;
	/* box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.3) !important; */
	border: 3px solid #1c1c1d !important;
}
.read-more:hover
{
	background: #000 !important;
	color: #fff !important;
}
.top-10
{
	margin-top: 20px !important;
}
.profile-image
{
	width: 100%;
}
.comment-info
{
	margin-left: 0px !important;
}
.c_name
{
	color: #000 !important;
	font-size: 1.6rem !important;
}
.label
{
	font-weight: bold;
	color: #000;
	width: 80px;
}
.comment
{
	margin-top: -40px !important;
}
.green
{
	color: #0f0 !important;
}
