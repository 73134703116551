.background
{
	background-color: #eee !important;
}
.bloglist .post-text {
  background: #fff !important;
    height: 420px !important;
}
.read-more
{
	background: #000 !important;
	color: #fff !important;
}
.title-padding
{
	margin-top: 50px !important;
}
