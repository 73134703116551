#comment-form-wrapper
{
	width: 60% !important;
}
.posting-load
{
	margin-top: 50px !important;
}
.form-title
{
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px !important;
    font-size: 30px !important;
    line-height: 42px !important;
    letter-spacing: -1px !important;
}
.login-link
{
    text-decoration: underline;
}
.form-card
{
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px !important;
}
.input
{
    height: 50px;
    width: 100% !important;
    border-radius: 3px;
    color: #111111;
    padding: 15px;
    display: inline-block;
    font-size: 16px;
    transition: all linear 0.5s;
    margin-bottom: 10px;
    border: solid 1px #e5e5e5;
    background-color: #f1f3f4;
    border: 1px solid #cacaca;
}
.area-input
{
    width: 100% !important;
    border-radius: 3px;
    color: #111111;
    padding: 15px;
    display: inline-block;
    font-size: 16px;
    transition: all linear 0.5s;
    margin-bottom: 10px;
    border: solid 1px #e5e5e5;
    background-color: #f1f3f4;
    border: 1px solid #cacaca;
}
.submit-button
{
    margin-top: 30px;
    width: 50% !important;
    height: 40px;
    color: #fff;
    background-color: #000;
}
.help-card h3
{
    font-size: 16px;
    font-weight: normal !important;
}
.help-card button
{
    width: 80%;
    border-radius: 50px;
    margin-top: 10px;
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
}
.help-card button:hover
{
    background-color: transparent;
    color: #000;
    border: 1px solid #000;
}
.kode-content section.kode-about-us-section {
    padding-bottom: 100px !important;
}
.login
{
    text-decoration: underline;
    text-align: center;
}
.success-message
{
    color: green;
    text-align: center !important;
}
