.pull-left
{
	float: left !important;
}
.pull-right
{
	float: left !important;
}
.user-search-card
{
	background-color: #fff;
	border-radius: 5px;
    width: 100% !important;
}

.user-search-card input
{
	height: 45px;
	width: 100% !important;
	border-radius: 3px;
	color: #111111;
	padding: 15px;
	display: inline-block;
	font-size: 16px;
	transition: all linear 0.5s;
	margin-bottom: 10px;
	border: solid 1px #e5e5e5;
	background-color: #f1f3f4;
	border: 1px solid #cacaca;
}
.user-search-card select
{
	width: 100% !important;
	border-radius: 3px;
	color: #111111;
	padding: 15px;
	display: inline-block;
	font-size: 16px;
	transition: all linear 0.5s;
	margin-bottom: 10px;
	border: solid 1px #e5e5e5;
	background-color: #f1f3f4;
}
.search-button
{
	width: 100% !important;
	height: 40px;
	color: #fff;
	background-color: #000;
}

