.user-details
{
	background-color: #fff !important;
	padding: 10px;
	border-radius: 10px;
	width: 90%;
	margin-left: 5%;
}
.user-details-h2
{
	font-size: 18px !important;
}
.user-details-p
{
	font-size: 14px !important;
}
.user-list
{
	background-color: #eee;
	padding: 20px;
	border-radius: 3px;
	height: 200px;
	border-right: 5px solid #000 !important;
}
.user-list img
{
	border-radius: 50%;
	width: 25%;
	float: left;
}
.user-list .details
{
	width: 60%;
	float: left;
	margin-left: 3%;
}
.view-button
{
	width: 5%;
	float: right;
	margin-top: 10%;
}
.view-button button
{
	background-color: transparent;
	border: 1px solid transparent;
}
.details h4
{
	line-height: 0 !important;
	margin-top: 10px !important;
}
.fpwow-icons {
	background-color: transparent !important;
	padding: 0px !important;
	margin-top: -15px !important;
}
.fpwow-icons i {
	color: #000 !important;
	padding: 10px !important;
	border-radius: 50% !important;
	border: 1px solid #000;
}


