.form-card
{
  background-color: #000 !important;
  border: 3px solid #1c1c1d !important;
}
body
{
  background-color: #19191A !important;
}
.sign-up-img2 {
  margin-top: -50% !important;
  border: 3px solid #1c1c1d !important;
}
.stroke-title
{
  color: transparent;
  -webkit-text-stroke: 1px #0f0; /* 4d4d4e */
  font-weight: 800;
  letter-spacing: .7px;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.form-control
{
  border: 1px solid #313135 !important;
  border-radius: 2px !important;
  background-color: transparent !important;
  font-weight: 400 !important;
  transition: all .3s ease !important;
  color: #fff !important;
  font-size: 16px !important;
  letter-spacing: -0.011em !important;
}
.form-control:focus {
  background-color: #1c222b !important;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  color: #BFBFBF;
  transition: opacity 0.1s ease-in-out,transform 0.1s ease-in-out,-webkit-transform 0.1s ease-in-out;
}
.small-text
{
  font-size: 16px !important;
}
.terms-and-conditions
{
  display: inline !important;
  text-decoration: underline #fff !important;
  text-underline-offset: 4px !important;
}
.text-green
{
  color: #0f0 !important;
  text-decoration: underline #0f0 !important;
}
.centered
{
  text-align: center !important;
}
.signup-button
{
  padding: 10px;
  background: #0f0;
  color: #000;
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.05em;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  outline: none;
  position: relative;
  width: 100%;
}
.loader-spin
{
  margin-left: 45% !important;
}
.black-background {
  /* background-color: #000 !important; */
}

