.no-data
{
	color: orange;
}
.section-title
{
	opacity: 0.5;
	margin-top: -20px;
	margin-bottom: 40px;
}
.transaction-details p
{
	font-size: 16px !important;
}

#container {
  position: relative;
  margin: auto;
  overflow: hidden;
  width: 700px;
  height: 280px;
}

h1 {
  font-size: 0.9em;
  font-weight: 100;
  letter-spacing: 3px;
  padding-top: 5px;
  color: #FCFCFC;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.green {
  color: darken($#99DBB4, 20%);
}

.red {
  color: darken(#EF8D9C, 10%);
}

.alert {
  font-weight: 700;
  letter-spacing: 5px;
}

.not-sent-error-message {
	font-size: 0.9em;
	font-weight: 100;
    margin-top: -70px;
    width: 87%;
    margin-left: 5%;
    color: #fff;
}

.error-info-text {
  margin-top: -40px !important;
}

button, .dot {
  cursor: pointer;
}

#error-box {
  position: absolute;
  width: 37%;
  height: 100%;
  left: 35%;
  background: linear-gradient(to bottom left, #EF8D9C 40%, #FFC39E 100%);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(#CBCDD3, 10%);
}

.dot {
  width: 8px;
  height: 8px;
  background: #FCFCFC;
  border-radius: 50%;
  position: absolute;
  top: 4%;
  right: 6%;
  &:hover {
    background: darken(#FCFCFC, 20%);
  }
}

.two {
  right: 12%;
  opacity: .5;
}

.face {
  position: absolute;
  width: 22%;
  height: 22%;
  background: #FCFCFC;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 21%;
  left: 37.5%;
  z-index: 2;
  animation: bounce 1s ease-in infinite;
}

.face2 {
  position: absolute;
  width: 22%;
  height: 15%;
  background: #FCFCFC;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 21%;
  left: 37.5%;
  z-index: 2;
  animation: roll 3s ease-in-out infinite;
}

.eye {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #777777;
  border-radius: 50%;
  top: 40%;
  left: 20%;
}

.right {
  left: 68%;
}

.mouth {
  position:absolute;
  top: 43%;
  left: 41%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.happy {
  border: 2px solid;
  border-color: transparent #777777 #777777 transparent;
  transform: rotate(45deg);
}

.sad {
  top: 49%;
  border: 2px solid;
  border-color: #777777 transparent transparent #777777;
  transform: rotate(45deg);
}

.shadow {
  position: absolute;
  width: 21%;
  height: 3%;
  opacity: .5;
  background: #777777;
  left: 40%;
  top: 36%;
  border-radius: 50%;
  z-index: 1;
}

.scale {
  animation: scale 1s ease-in infinite;
}
.move {
  animation: move 3s ease-in-out infinite;
}


.message {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 40%;
  top: 40%;
}

.button-box {
  position: absolute;
  background: #FCFCFC;
  width: 50%;
  height: 15%;
  border-radius: 20px;
  top: 80.5%;
  left: 25%;
  outline: 0;
  border: none;
  font-size: 0.9rem !important;
  box-shadow: 2px 2px 10px rgba(#777777, .5);
  transition: all .5s ease-in-out;
  &:hover {
    background: darken(#FCFCFC, 5%);
    transform: scale(1.05);
    transition: all .3s ease-in-out;
  }
}

@keyframes bounce {
  50% {
     transform: translateY(-10px);
  }
}

@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
    left: 25%;
  }
  50% {
    left: 60%;
    transform: rotate(168deg);
  }
  100% {
    transform: rotate(0deg);
    left: 25%;
  }
}

@keyframes move {
  0% {
    left: 25%;
  }
  50% {
    left: 60%;
  }
  100% {
    left: 25%;
  }
}
.try-again-tex
{
	z-index: 99 !important;
	position: absolute !important;
}

.money-sent-by-user
{
    padding: 10px;
	border-radius: 5px;
    border: 1px solid rgba(6, 241, 226, 0.81);
    background-color: rgba(1, 204, 220, 0.16);
    box-shadow: 0px 0px 2px #03fff5;
    color: #03d0ff;
    transition:0.5s;
    cursor:pointer;
    margin-bottom: 20px;
}
.money-sent-by-user:hover
{
	background-color: rgba(1, 204, 220, 0.33);
	transition:0.5s;
}
.money-not-withdrawn-by-user
{
    padding: 10px;
	border-radius: 5px;
    border: 1px solid rgba(6, 44, 241, 0.46);
    background-color: rgba(7, 73, 149, 0.12156862745098039);
    box-shadow: 0px 0px 2px #0396ff;
    color: #0396ff;
    transition:0.5s;
    cursor:pointer;
    margin-bottom: 20px;
}
.money-not-withdrawn-by-user:hover
{
  	background-color: rgba(7, 73, 149, 0.35);
  	transition:0.5s;
}
.money-withdrawn-by-user
{
	color: #25ff0b;
	border: 1px solid rgba(36, 241, 6, 0.46);
	border-radius: 5px;
    background-color: rgba(7, 149, 66, 0.12156862745098039);
    box-shadow: 0px 0px 2px #259c08;
    color: #0ad406;
    transition:0.5s;
    cursor:pointer;
    padding: 10px;
    margin-bottom: 20px;
}
.money-withdrawn-by-user:hover
{
	background-color: rgba(7, 149, 66, 0.35);
	transition:0.5s;
}
.success-text
{
	color: #0ad406;
}
.warning-text
{
	color: #ffb40b;
}
.medium-title
{
	font-size: 18px;
}
.money-sent-by-admin-button {
    padding: 10px 30px;
    font-weight: 600;
    text-align: center;
    background: #0396ff;
    color: #fff;
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid #0396ff;
    align-items: center;
    width: 49%;
    float: left;
}
.money-received-button {
    padding: 10px 30px;
    font-weight: 600;
    text-align: center;
    background: #0ad406;
    color: #fff;
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid #0ad406;
    align-items: center;
    width: 49%;
    float: right;
}
.margin-top
{
	margin-top: 20px;
}
.margin-bottom {
  margin-bottom: 20px !important;
}
