.database-section
{
	/*background-color: #f5f5f5;
*/}
.card
{
	background-color: #fff !important;	
	border-radius: 3px !important;
	border: 1px solid transparent !important;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12),0 3px 1px -2px rgba(0,0,0,.2) !important;
}
.white-background
{
	background-color: #fff !important;
}
.read-more
{
	background: #fff !important;
	color: #000 !important;
	box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.3) !important;
}
.read-more:hover
{
	background: #000 !important;
	color: #fff !important;
}
.top-10
{
	margin-top: 20px !important;
}
.profile-image
{
	width: 100%;
}
.comment-info
{
	margin-left: 0px !important;
}
.c_name
{
	color: #000 !important;
	font-size: 1.6rem !important;
}
.label
{
	font-weight: bold;
	color: #000;
	width: 80px;
}
.comment
{
	margin-top: -40px !important;
}
section
{
	padding-top: 20px !important;
}
.agreement-button
{
	margin-top: 30px;
	color: #000 !important;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 30px;
	padding-right: 30px;
}
.image-round-blank-background img {
	box-shadow: 2px 20px 30px 0px rgba(20, 20, 20, 0.3);
}
.about-section
{
	background-color: #111111 !important;
	padding-top: 60px !important;
}
.missions-section h5
{
	color: #fff !important;
	font-size: 20px !important;
	border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
}
.missions-section p
{
	padding-bottom: 20px !important;
}
.add-database:hover
{
	background-color: transparent !important;
	color: #000 !important;
	border: 1px solid #000 !important;
}
