.bar-separator
{
	margin-top: 3% !important;
}
.to-chat-button
{
	background-color: #fff;
    color: #000;
    border: 2px solid #000;
    width: 60px;
    height: 60px;
    line-height: 50px;
    font-size: 1.6rem;
    display: block;
    text-align: center;
    border-radius: 50%;
    float: right;
    /*margin-right: 7%;*/
    margin-top: 13%;
    z-index: 1070;
}
.to-chat-button:hover
{
    background-color: #000;
    color: #fff;
    border: 2px solid #fff;
}
.danger-button
{
    border: 1px solid #D8000C !important;
    color: #D8000C !important;
    background-color: #D8000C !important;
}
.danger-button:hover
{
    background-color: #D8000C !important;
    color: #fff !important;
    border: 1px solid #D8000C !important;
}
.signout-icon
{
    width: 20% !important;
}


.mobile-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #000;
    
    overflow: hidden;
    max-height: 0; /* Start with a height of 0 */
    transition: max-height 0.9s ease-out; /* Add transition */
}

.mobile-menu.show {
    max-height: 500px; /* Adjust the maximum height based on your content */
}
  
.mobile-menu li {
    border-bottom: 1px solid #19191a;
}

.mobile-menu li a {
    display: block;
    color: #fff;
    padding: 18px 16px;
    text-decoration: none;
}

.mobile-menu li a:hover {
    background-color: #19191a;
    color: white;
}

.mobile-menu-button
{
    display: none;
    width: 100px;
    background-color: #19191a;
    color: #fff;
    border: 1px solid #000;
    padding: 5px;
    margin-top: 10px;
}
.mobile-menu-button:hover
{
    background-color: #000;
}
.menu-img
{
    width: 35%;
}

@media (max-width: 980px) {
    .mobile-menu-button
    {
        display: block;
    }
    .navbar-brand {
        width: 20% !important;
        padding-top: 15px;
    }
}
